import attSVG from 'assets/icons/companies/grayscale/logo-att.svg'
import whirlpoolSVG from 'assets/icons/companies/grayscale/logo-whirlpool.svg'
import telecomSVG from 'assets/icons/companies/grayscale/logo-telecom.svg'
import directtvSVG from 'assets/icons/companies/grayscale/logo-directtv.svg'
import saintgobainSVG from 'assets/icons/companies/grayscale/logo-saintgobain.svg'
import abletoSVG from 'assets/icons/companies/grayscale/logo-ableto.svg'
import agrotokenSVG from 'assets/icons/companies/grayscale/logo-agrotoken.svg'
import andreaniSVG from 'assets/icons/companies/grayscale/logo-andreani.svg'
import appaSVG from 'assets/icons/companies/grayscale/logo-appa.svg'
import avenidaSVG from 'assets/icons/companies/grayscale/logo-avenida.svg'
import c6bankSVG from 'assets/icons/companies/grayscale/logo-c6bank.svg'
import clublanacionSVG from 'assets/icons/companies/grayscale/logo-clublanacion.svg'
import DHSVG from 'assets/icons/companies/grayscale/logo-DH.svg'
import gcbaSVG from 'assets/icons/companies/grayscale/logo-gcba.svg'
import hipcamSVG from 'assets/icons/companies/grayscale/logo-hipcam.svg'
import lacajaSVG from 'assets/icons/companies/grayscale/logo-lacaja.svg'
import lomaSVG from 'assets/icons/companies/grayscale/logo-loma.svg'
import naventSVG from 'assets/icons/companies/grayscale/logo-navent.svg'
import octopusSVG from 'assets/icons/companies/grayscale/logo-octopus.svg'
import olxSVG from 'assets/icons/companies/grayscale/logo-olx.svg'
import paretoSVG from 'assets/icons/companies/grayscale/logo-pareto.svg'
import pasapSVG from 'assets/icons/companies/grayscale/logo-pasap.svg'
import rskSVG from 'assets/icons/companies/grayscale/logo-rsk.svg'
import santanderSVG from 'assets/icons/companies/grayscale/logo-santander.svg'
import superdigitalSVG from 'assets/icons/companies/grayscale/logo-superdigital.svg'
import tokkoSVG from 'assets/icons/companies/grayscale/logo-tokko.svg'
import YahooSVG from 'assets/icons/companies/grayscale/logo-Yahoo.svg'
import zonajobsSVG from 'assets/icons/companies/grayscale/logo-zonajobs.svg'
import azetaSVG from 'assets/icons/companies/grayscale/logo-azeta.svg'
import lazoSVG from 'assets/icons/companies/grayscale/logo-lazo.svg'
import medicusSVG from 'assets/icons/companies/grayscale/logo-medicus.svg'
import expansionSVG from 'assets/icons/companies/grayscale/logo-expansion.svg'

// PNGs
import n5PNG from 'assets/icons/companies/grayscale/logo-n5.webp'
import megalabsPNG from 'assets/icons/companies/grayscale/logo-megalabs.webp'
import uplPNG from 'assets/icons/companies/grayscale/logo-upl.webp'
import atomPNG from 'assets/icons/companies/grayscale/logo-atom.webp'
import emergenciasSaludPNG from 'assets/icons/companies/grayscale/logo-emergencias_salud.webp'
import smartStructuringPNG from 'assets/icons/companies/grayscale/logo-smart_structuring.webp'
import zaveePNG from 'assets/icons/companies/grayscale/logo-zavee.webp'
import crcPressPNG from 'assets/icons/companies/grayscale/logo-crc_press.webp'
import trocafonePNG from 'assets/icons/companies/grayscale/logo-trocafone.webp'

const COMPANIES = [
  {
    name: 'at&t',
    logo: attSVG,
    width: '92px',
    height: '38px',
  },
  {
    name: 'whirlpool',
    logo: whirlpoolSVG,
    width: '109px',
    height: '37px',
  },
  {
    name: 'direct tv',
    logo: directtvSVG,
    width: '105px',
    height: '21px',
  },
  {
    name: 'telecom',
    logo: telecomSVG,
    width: '124px',
    height: '24px',
  },
  {
    name: 'saint gobain',
    logo: saintgobainSVG,
    width: '111px',
    height: '47px',
  },
  {
    name: 'azeta',
    logo: azetaSVG,
    width: '150px',
    height: '20px',
  },
  {
    name: 'lazo',
    logo: lazoSVG,
    width: '93px',
    height: '24px',
  },
  {
    name: 'c6bank',
    logo: c6bankSVG,
    width: '128px',
    height: '26px',
  },
  {
    name: 'andreani',
    logo: andreaniSVG,
    width: '178px',
    height: '21px',
  },
  {
    name: 'ableto',
    logo: abletoSVG,
    width: '123px',
    height: '27px',
  },
  {
    name: 'santander',
    logo: santanderSVG,
    width: '179px',
    height: '32px',
  },
  {
    name: 'n5',
    logo: n5PNG,
    width: '42px',
    height: '42px',
  },
  {
    name: 'appa',
    logo: appaSVG,
    width: '120px',
    height: '34px',
  },
  {
    name: 'medicus',
    logo: medicusSVG,
    width: '117px',
    height: '24px',
  },
  // fin primer linea
  {
    name: 'pareto',
    logo: paretoSVG,
    width: '122px',
    height: '31px',
  },
  {
    name: 'olx',
    logo: olxSVG,
    width: '66px',
    height: '39px',
  },
  {
    name: 'megalabs',
    logo: megalabsPNG,
    width: '157px',
    height: '35px',
  },
  {
    name: 'agrotoken',
    logo: agrotokenSVG,
    width: '201px',
    height: '33px',
  },
  {
    name: 'pasap',
    logo: pasapSVG,
    width: '138px',
    height: '34px',
  },
  {
    name: 'navent',
    logo: naventSVG,
    width: '128px',
    height: '28px',
  },
  {
    name: 'upl',
    logo: uplPNG,
    width: '114px',
    height: '54px',
  },
  // fin segunda linea
  {
    name: 'atom',
    logo: atomPNG,
    width: '104px',
    height: '36px',
  },
  {
    name: 'emergencias',
    logo: emergenciasSaludPNG,
    width: '123px',
    height: '48px',
  },
  {
    name: 'rsk',
    logo: rskSVG,
    width: '72px',
    height: '26px',
  },
  {
    name: 'DH',
    logo: DHSVG,
    width: '152px',
    height: '12px',
  },
  {
    name: 'smartStructuring',
    logo: smartStructuringPNG,
    width: '131px',
    height: '38px',
  },
  {
    name: 'octopus',
    logo: octopusSVG,
    width: '171px',
    height: '50px',
  },
  // fin tercera linea
  {
    name: 'zavee',
    logo: zaveePNG,
    width: '144px',
    height: '39px',
  },
  {
    name: 'Yahoo',
    logo: YahooSVG,
    width: '101px',
    height: '44px',
  },
  {
    name: 'hipcam',
    logo: hipcamSVG,
    width: '144px',
    height: '29px',
  },
  {
    name: 'avenida',
    logo: avenidaSVG,
    width: '147px',
    height: '37px',
  },
  {
    name: 'clublanacion',
    logo: clublanacionSVG,
    width: '73px',
    height: '43px',
  },
  {
    name: 'crcPress',
    logo: crcPressPNG,
    width: '166px',
    height: '49px',
  },
  {
    name: 'lacaja',
    logo: lacajaSVG,
    width: '46px',
    height: '54px',
  },
  // fin cuarta linea
  {
    name: 'zonajobs',
    logo: zonajobsSVG,
    width: '108px',
    height: '48px',
  },
  {
    name: 'gcba',
    logo: gcbaSVG,
    width: '273px',
    height: '42px',
  },
  {
    name: 'trocafone',
    logo: trocafonePNG,
    width: '153px',
    height: '33px',
  },
  {
    name: 'tokko',
    logo: tokkoSVG,
    width: '80px',
    height: '48px',
  },
  {
    name: 'superdigital',
    logo: superdigitalSVG,
    width: '119px',
    height: '28px',
  },
  {
    name: 'loma',
    logo: lomaSVG,
    width: '48px',
    height: '48px',
  },
  {
    name: 'expansion',
    logo: expansionSVG,
    width: '130px',
    height: '35px',
  },
]

export default COMPANIES
