import React from 'react'
import SuccessCasesSubseccion from 'presentation/SucessCasesSubsection'
import CompaniesSubsection from 'presentation/CompaniesSubsection'

import styles from './portfolio.module.scss'

export default function Portfolio() {
  return (
    <div className={styles.container}>
      <CompaniesSubsection />
      <SuccessCasesSubseccion />
    </div>
  )
}
