import React, { useMemo } from 'react'
import { Carousel } from 'react-responsive-carousel'
import { useMediaQuery } from 'react-responsive'
import { v4 as uuidv4 } from 'uuid'
import { useTranslation } from 'react-i18next'
import c from 'classnames'
import CompanyCard from 'presentation/CompanyCard'
import Title from 'presentation/Title'

import arrowLeftSVG from 'assets/icons/carousel_arrow_left.svg'
import arrowRightSVG from 'assets/icons/carousel_arrow_right.svg'
import styles from './success_cases_subsection.module.scss'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import './carousel.portfolio.scss'

const COMPANIES = [
  'santander',
  'n5',
  'pasap',
  'olx',
  'ableTo',
  'smartStructuring',
  'andreani',
  'atom',
  'emergencias',
  'megalabs',
  'agrotoken',
  'lacaja',
]

export default function SuccessCasesSubseccion() {
  const { t } = useTranslation('Companies')

  const canRender4Cards = useMediaQuery({
    query: '(min-width: 1350px)',
  })

  const canRender3Cards = useMediaQuery({
    query: '(min-width: 1050px)',
  })

  const canRender2Cards = useMediaQuery({
    query: '(min-width: 750px)',
  })

  const showIndicators = useMediaQuery({
    query: '(max-width: 480px)',
  })

  const companySlideGroup = useMemo(() => {
    let companiesPerGroup
    const groups = []

    if (canRender4Cards) {
      companiesPerGroup = 4
    } else if (canRender3Cards) {
      companiesPerGroup = 3
    } else if (canRender2Cards) {
      companiesPerGroup = 2
    } else {
      companiesPerGroup = 1
    }

    for (let i = 0; i < COMPANIES.length; i += companiesPerGroup) {
      groups.push({
        groupId: uuidv4(),
        companies: COMPANIES.slice(i, i + companiesPerGroup),
      })
    }

    return groups
  }, [canRender4Cards, canRender3Cards, canRender2Cards])

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Title title={t('Title')} />
      </div>
      <div className={styles.carrousel}>
        <Carousel
          ariaLabel="Portfolio"
          className="portfolio"
          showStatus={false}
          showThumbs={false}
          showIndicators={showIndicators}
          showArrows={!showIndicators}
          autoPlay
          stopOnHover
          infiniteLoop
          transitionTime={1500}
          interval={5000}
          renderArrowPrev={(onClickHandler, hasPrev, label) =>
            hasPrev && (
              <button
                type="button"
                onClick={onClickHandler}
                title={label}
                className={c(styles.carousel_arrow, styles.carousel_arrow__left)}
              >
                <img alt={label} src={arrowLeftSVG} />
              </button>
            )
          }
          renderArrowNext={(onClickHandler, hasNext, label) =>
            hasNext && (
              <button
                type="button"
                onClick={onClickHandler}
                title={label}
                className={c(styles.carousel_arrow, styles.carousel_arrow__right)}
              >
                <img alt={label} src={arrowRightSVG} />
              </button>
            )
          }
          preventMovementUntilSwipeScrollTolerance
          swipeScrollTolerance={50}
        >
          {companySlideGroup.map(({ groupId, companies }) => (
            <div key={groupId} className={styles.carrousel_group}>
              {companies.map((companyName) => (
                <CompanyCard key={companyName} companyName={companyName} />
              ))}
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  )
}
