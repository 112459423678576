import React from 'react'
import { useTranslation, getI18n } from 'react-i18next'
import FormInput from 'presentation/FormInput'
import Message from 'presentation/Message'
import Button from 'presentation/Button/Button'
import background1 from 'assets/images/circle.svg'
import background3 from 'assets/images/polygon2.svg'

import styles from './contact.module.scss'

export default function Contact() {
  const { t } = useTranslation('Contact')

  const [loading, setLoading] = React.useState(false)
  const [firstname, setFirstname] = React.useState('')
  const [lastname, setLastname] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [company, setCompany] = React.useState('')
  const [comment, setComment] = React.useState('')

  const [successMessage, setSuccessMessage] = React.useState('')
  const [errorMessage, setErrorMessage] = React.useState('')

  const clearData = () => {
    setFirstname('')
    setLastname('')
    setEmail('')
    setCompany('')
    setComment('')
  }

  const onSubmitHandler = (event) => {
    event.preventDefault()

    setLoading(true)
    setErrorMessage('')
    setSuccessMessage('')

    fetch('/contact_form.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        language: getI18n().language,
        first_name: firstname,
        last_name: lastname,
        email,
        company,
        comment,
      }),
    })
      .then((response) => {
        if (response.ok) {
          setSuccessMessage(t('Form.SuccessMessage'))
          clearData()
        } else {
          throw new Error(t('Form.ErrorMessage'))
        }

        setTimeout(() => {
          setSuccessMessage('')
        }, 5000)
      })
      .catch(() => {
        setErrorMessage(t('Form.ErrorMessage'))
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <section className={styles.wrapper}>
      <div className={styles.circle} style={{ backgroundImage: `url(${background1})` }} />
      <div className={styles.poligon} style={{ backgroundImage: `url(${background3})` }} />
      <h3 className={styles.phrase}>
        <span>{t('Phrase1')}</span> <br />
        {t('Phrase2')}
      </h3>
      <div className={styles.container}>
        <div className={styles.row_md_2}>
          <div className={styles.text}>
            <h4 className={styles.title}>{t('Title')}</h4>
            <p className={styles.description}>{t('Description')}</p>
          </div>
          <div>
            <h4 className={styles.primaryTitle} id="contact">
              {t('PrimaryTitle')}
            </h4>
            <form id="ga-contact_form" onSubmit={onSubmitHandler} className={styles.form}>
              <div className={styles.row_sm_2}>
                <FormInput
                  name="first_name"
                  label={t('Form.FirstName')}
                  required
                  value={firstname}
                  onChange={setFirstname}
                  disabled={loading}
                />
                <FormInput
                  name="last_name"
                  label={t('Form.LastName')}
                  required
                  value={lastname}
                  onChange={setLastname}
                  disabled={loading}
                />
              </div>
              <div className={styles.row_sm_2}>
                <FormInput
                  name="email"
                  label={t('Form.Email')}
                  type="email"
                  required
                  value={email}
                  onChange={setEmail}
                  disabled={loading}
                />
                <FormInput
                  name="company"
                  label={t('Form.Company')}
                  value={company}
                  onChange={setCompany}
                  disabled={loading}
                />
              </div>
              <FormInput
                name="comment"
                label={t('Form.Message')}
                required
                multiline
                value={comment}
                onChange={setComment}
                disabled={loading}
                rows="10"
              />
              {successMessage && <Message type="success">{successMessage}</Message>}
              {errorMessage && <Message type="error">{errorMessage}</Message>}
              <Button type="submit" disabled={loading}>
                {t('Form.Submit')}
              </Button>
              {loading && <div className={styles.loading}>{t('Form.Loading')}</div>}
            </form>
          </div>
        </div>
      </div>
    </section>
  )
}
