import React from 'react'
import { useTranslation } from 'react-i18next'
import Title from 'presentation/Title'

import metrics1 from 'assets/images/metrics-1.webp'
import metrics2 from 'assets/images/metrics-2.webp'
import metrics3 from 'assets/images/metrics-3.webp'
import metrics4 from 'assets/images/metrics-4.webp'
import metrics5 from 'assets/images/metrics-5.webp'
import metrics6 from 'assets/images/metrics-6.webp'

import styles from './we_are_digbang_subsection.module.scss'

const METRIC_BACKGROUNDS = {
  Hours: metrics1,
  Tenure: metrics2,
  Impact: metrics3,
  Proyects: metrics4,
  Unicorns: metrics5,
  Countries: metrics6,
}

export default function WeAreDigbangSubsection() {
  const { t } = useTranslation('Services')

  return (
    <div className={styles.subsection}>
      <div className={styles.header_2}>
        <Title pretitle={t('WeAreDigBang')} title={t('OurImpact')} className={styles} />
      </div>
      <div className={styles.metrics__container}>
        <div className={styles.metrics__mask}>
          {['Hours', 'Tenure', 'Impact', 'Proyects', 'Unicorns', 'Countries'].map((metricName) => (
            <div className={styles.metrics__item} key={metricName}>
              <img
                className={styles.metrics__image}
                alt={metricName}
                src={METRIC_BACKGROUNDS[metricName]}
                width="388px"
                height="186px"
              />
              <div className={styles.metrics__item_number}>{t(`Metrics.${metricName}.Number`)}</div>
              <div className={styles.metrics__item_description}>
                {t(`Metrics.${metricName}.Description`)}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
