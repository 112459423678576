import { useEffect } from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import i18n from 'util/i18n'
import { LANG, setHtmlLang } from 'util/language'
import { Home } from 'scenes'
import { getI18n } from 'react-i18next'

import './styles/base.scss'

function App() {
  useEffect(() => {
    const lng = document.location.pathname.split('/')[2] || LANG
    i18n.init({ lng })
    setHtmlLang(lng)
  }, [])

  return (
    <BrowserRouter>
      <Switch>
        <Redirect exact path="/" to="/hero" />
        <Redirect exact path="/:section" to={`/:section/${getI18n().language}`} />
        <Route path="/:section/:lang" component={Home} />
      </Switch>
    </BrowserRouter>
  )
}

export default App
