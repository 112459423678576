import { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import Hero from 'presentation/Hero'
import OurServices from 'presentation/OurServices'
import Contact from 'presentation/Contact'
import Portfolio from 'presentation/Portfolio'
import Technologies from 'presentation/Technologies'
import Section from './Section'

import styles from './sections.module.scss'

export default function Sections() {
  const { ref, inView } = useInView({
    rootMargin: '4%',
    threshold: 0,
  })

  useEffect(() => {
    const root = document.documentElement

    if (inView) {
      root.style.setProperty('--header__transparency', 'initial')
      return
    }

    root.style.removeProperty('--header__transparency')
  }, [inView])

  return (
    <div className={styles.sections__container}>
      <div className={styles.inViewRef} ref={ref} />
      <Section id="hero">
        <Hero />
      </Section>
      <Section id="ourservices">
        <OurServices />
      </Section>
      <Section id="customers">
        <Portfolio />
      </Section>
      <Section id="technologies">
        <Technologies />
      </Section>
      <Section id="contact">
        <Contact />
      </Section>
    </div>
  )
}
