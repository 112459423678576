import React from 'react'
import WhatWeDoSubsection from 'presentation/WhatWeDoSubsection'
import WeAreDigbangSubsection from 'presentation/WeAreDigbangSubsection'
import background1 from 'assets/images/circle.svg'
import background2 from 'assets/images/line-circle.svg'

import styles from './our_services.module.scss'

export default function OurServices({ ...props }) {
  return (
    <div className={styles.container} {...props}>
      <div className={styles.circle} style={{ backgroundImage: `url(${background1})` }} />
      <div className={styles.circle2} style={{ backgroundImage: `url(${background2})` }} />
      <WhatWeDoSubsection />
      <WeAreDigbangSubsection />
    </div>
  )
}
