import PropTypes from 'prop-types'
import c from 'classnames'
import { setHtmlLang, detectLang } from 'util/language'
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom'
import i18n from 'util/i18n'
import styles from './languageSelector.module.scss'

function LanguageSelector({ className }) {
  const defaultLang = detectLang()
  const history = useHistory()
  const { section } = useParams()

  const clickHandler = ({ target }) => {
    const lng = target.lang ?? defaultLang
    i18n.init({ lng })
    setHtmlLang(lng)
    history.push(`/${section}/${lng}`)
  }

  return (
    <div className={c(styles.language__container, className)}>
      <div
        role="button"
        tabIndex={0}
        className={c(styles.spanish, styles.lang)}
        lang="es"
        onClick={clickHandler}
      >
        <img className={styles.flag} src="/assets/icons/spanish-flag.svg" alt="Español" />
        <div className={styles.language}>Español</div>
      </div>
      <div
        role="button"
        tabIndex={0}
        className={c(styles.spanish, styles.lang)}
        lang="en"
        onClick={clickHandler}
      >
        <img className={styles.flag} src="/assets/icons/us-flag.svg" alt="English" />
        <div className={styles.language}>English</div>
      </div>
    </div>
  )
}

LanguageSelector.propTypes = {
  className: PropTypes.string,
}

LanguageSelector.defaultProps = {
  className: null,
}

export default LanguageSelector
