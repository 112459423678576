import React, { useMemo } from 'react'
import Title from 'presentation/Title'
import { Carousel } from 'react-responsive-carousel'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import { v4 as uuidv4 } from 'uuid'
import c from 'classnames'

import styles from './companies_subsection.module.scss'
import COMPANIES from './Companies'

export default function CompaniesSubsection() {
  const { t } = useTranslation('Portfolio')

  const shouldRender1Slide = useMediaQuery({
    query: '(min-width: 1280px)',
  })

  const shouldRender2Slides = useMediaQuery({
    query: '(min-width: 768px)',
  })

  const shouldRender3Slides = useMediaQuery({
    query: '(min-width: 576px)',
  })

  const shouldRender4Slides = useMediaQuery({
    query: '(min-width: 349px)',
  })

  const brandsSlideGroup = useMemo(() => {
    let brandsPerGroup
    const groups = []

    if (shouldRender1Slide) {
      brandsPerGroup = COMPANIES.length
    } else if (shouldRender2Slides) {
      brandsPerGroup = COMPANIES.length / 2
    } else if (shouldRender3Slides) {
      brandsPerGroup = COMPANIES.length / 3
    } else if (shouldRender4Slides) {
      brandsPerGroup = COMPANIES.length / 4
    } else {
      brandsPerGroup = COMPANIES.length / 5
    }

    for (let i = 0; i < COMPANIES.length; i += brandsPerGroup) {
      groups.push({
        groupId: uuidv4(),
        brands: COMPANIES.slice(i, i + brandsPerGroup),
      })
    }

    return groups
  }, [shouldRender1Slide, shouldRender2Slides, shouldRender3Slides, shouldRender4Slides])

  return (
    <div className={styles.container}>
      <Title className={styles} pretitle={t('Pretitle')} title={t('Title')} secondary />
      <div className={styles.carousel}>
        <Carousel
          ariaLabel="brands"
          showStatus={false}
          showThumbs={false}
          showIndicators={!shouldRender1Slide}
          showArrows={false}
          autoPlay
          stopOnHover
          infiniteLoop
          transitionTime={1500}
          interval={5000}
          preventMovementUntilSwipeScrollTolerance
          swipeScrollTolerance={50}
        >
          {brandsSlideGroup.map(({ groupId, brands }) => (
            <div key={groupId} className={styles.brands_container}>
              {brands.map(({ name, logo, width, height }) => (
                <img
                  key={name}
                  className={c(styles.brands_logo, styles[name])}
                  alt={name}
                  src={logo}
                  width={width}
                  height={height}
                />
              ))}
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  )
}
