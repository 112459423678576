import PropTypes from 'prop-types'
import Menu from 'presentation/Menu'
import c from 'classnames'
import LanguageSelector from 'presentation/LanguageSelector'
import styles from './header.module.scss'

function Header({ className }) {
  return (
    <>
      <div className={c(styles.header__container, className)}>
        <a className={styles.db_logo} href="/">
          <img src="/assets/logos/digbang_white.svg" alt="Digbang" width="147px" height="29px" />
        </a>
        <Menu className={styles.menu} />
        <LanguageSelector className={styles.language__selector} />
      </div>
      <div className={styles.header__space} />
    </>
  )
}

Header.propTypes = {
  className: PropTypes.string,
}

Header.defaultProps = {
  className: '',
}

export default Header
