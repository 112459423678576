import awsPNG from 'assets/icons/technologies/logo-aws.webp'
import azurePNG from 'assets/icons/technologies/logo-azure.webp'
import elasticPNG from 'assets/icons/technologies/logo-elastic.webp'
import googlecloudPNG from 'assets/icons/technologies/logo-googlecloud.webp'
import kafkaPNG from 'assets/icons/technologies/logo-kafka.webp'
import laravelPNG from 'assets/icons/technologies/logo-laravel.webp'
import mongoPNG from 'assets/icons/technologies/logo-mongo.webp'
import mysqlPNG from 'assets/icons/technologies/logo-mysql.webp'
import netSVG from 'assets/icons/technologies/logo-net.svg'
import nginxPNG from 'assets/icons/technologies/logo-nginx.webp'
import nodePNG from 'assets/icons/technologies/logo-node.webp'
import phpPNG from 'assets/icons/technologies/logo-php.webp'
import postgresqlPNG from 'assets/icons/technologies/logo-postgresql.webp'
import pythonPNG from 'assets/icons/technologies/logo-python.webp'
import rabbitPNG from 'assets/icons/technologies/logo-rabbit.webp'
import reactjsPNG from 'assets/icons/technologies/logo-reactjs.webp'
import reactnativePNG from 'assets/icons/technologies/logo-reactnative.webp'
import redisPNG from 'assets/icons/technologies/logo-redis.webp'
import sqlserverPNG from 'assets/icons/technologies/logo-sqlserver.webp'
import symfonyPNG from 'assets/icons/technologies/logo-symfony.webp'

const TECHNOLOGIES = [
  { name: 'kafka', logo: kafkaPNG, width: '134px', height: '83px' },
  { name: 'net', logo: netSVG, width: '74px', height: '31px' },
  { name: 'node', logo: nodePNG, width: '209px', height: '57px' },
  { name: 'php', logo: phpPNG, width: '135px', height: '76px' },
  { name: 'laravel', logo: laravelPNG, width: '139px', height: '123px' },
  { name: 'symfony', logo: symfonyPNG, width: '195px', height: '59px' },
  { name: 'nginx', logo: nginxPNG, width: '168px', height: '75px' },
  { name: 'redis', logo: redisPNG, width: '154px', height: '52px' },
  { name: 'rabbit', logo: rabbitPNG, width: '162px', height: '92px' },

  { name: 'elastic', logo: elasticPNG, width: '189px', height: '51px' },

  { name: 'python', logo: pythonPNG, width: '219px', height: '66px' },
  { name: 'reactjs', logo: reactjsPNG, width: '114px', height: '121px' },
  { name: 'reactnative', logo: reactnativePNG, width: '126px', height: '121px' },
  { name: 'mysql', logo: mysqlPNG, width: '164px', height: '87px' },
  { name: 'postgresql', logo: postgresqlPNG, width: '249px', height: '74px' },
  { name: 'azure', logo: azurePNG, width: '186px', height: '52px' },
  { name: 'googlecloud', logo: googlecloudPNG, width: '287px', height: '88px' },
  { name: 'sqlserver', logo: sqlserverPNG, width: '241px', height: '88px' },
  { name: 'mongo', logo: mongoPNG, width: '242px', height: '66px' },

  { name: 'aws', logo: awsPNG, width: '104px', height: '63px' },
]

export default TECHNOLOGIES
