import { useTranslation } from 'react-i18next'
import c from 'classnames'
import i18n from 'util/i18n'
import Button from 'presentation/Button'
import styles from './footer.module.scss'

const {
  REACT_APP_OLD_SITE_URL,
  REACT_APP_PHONENUMBER_1,
  REACT_APP_PHONENUMBER_2,
  REACT_APP_ADDRESS_1,
  REACT_APP_ADDRESS_2,
  REACT_APP_ADDRESS_3,
  REACT_APP_ADDRESS_4,
} = process.env

const Contact = () => {
  const { t } = useTranslation('Footer')

  return (
    <div className={styles.footer}>
      <div className={styles.left_container}>
        <a className={styles.db_logo} href="/">
          <img src="/assets/logos/digbang_white.svg" alt="Digbang" width="116" height="23" />
        </a>
        <a className={c(styles.email, styles.circle_mask)} href="mailto:sales@digbang.com">
          <img
            src="/assets/icons/envelope.svg"
            className={styles.envelope}
            alt="eMail"
            width="21"
            height="16.5"
          />
        </a>
        <a
          className={c(styles.linkedin, styles.circle_mask)}
          href="https://www.linkedin.com/company/digbang"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src="/assets/icons/linkedin.svg"
            className={styles.linkedin_logo}
            alt="Linkedin"
            width="18"
            height="18"
          />
        </a>
        <a
          className={styles.clutch}
          href="https://clutch.co/profile/digbang"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src="/assets/icons/clutch.svg"
            className={styles.clutch_logo}
            alt="Clutch"
            width="57"
            height="16"
          />
        </a>
        <a className={styles.email__text} href="mailto:sales@digbang.com">
          sales@digbang.com
        </a>
      </div>
      <a
        className={styles.middle_container}
        href={`${REACT_APP_OLD_SITE_URL}?lg=${i18n.language}`}
        target="_blank"
        rel="noreferrer"
      >
        <Button className={styles.cta}>{t('CTA_text')}</Button>
      </a>
      <div className={styles.right_container}>
        <div className={c(styles.address)}>
          <p className={styles.nowrap}>{REACT_APP_ADDRESS_1}</p>
          <p className={styles.nowrap}>{REACT_APP_ADDRESS_2}</p>
        </div>
        <p className={c(styles.phone, styles.nowrap)}>{REACT_APP_PHONENUMBER_1}</p>
        <div className={c(styles.address)}>
          <p className={styles.nowrap}>{REACT_APP_ADDRESS_3}</p>
          <p className={styles.nowrap}>{REACT_APP_ADDRESS_4}</p>
        </div>
        <p className={c(styles.phone, styles.nowrap)}>{REACT_APP_PHONENUMBER_2}</p>
      </div>
    </div>
  )
}

export default Contact
