const {
  REACT_APP_DEFAULTLANG = 'es',
  REACT_APP_FALLBACKLANG = process.env.REACT_APP_DEFAULTLANG,
  REACT_APP_LANGUAGES = process.env.REACT_APP_DEFAULTLANG,
} = process.env

/**
 * Array of supported languages (BCP47) on the site, defined on the variable REACT_APP_LANGUAGES of the environment
 * @constant
 * @type {Array<String>}
 * @default
 */
export const LANGUAGES = REACT_APP_LANGUAGES?.split(/,\s*/)

/**
 * Default language (BCP47), defined on the variable REACT_APP_DEFAULTLANG of the environment
 * @constant
 * @type {String}
 * @default
 */
export const DEFAULTLANG = REACT_APP_DEFAULTLANG

/**
 * Fallback language (BCP47), defined on the variable REACT_APP_FALLBACKLANG of the environment (default: DEFAULTLANG)
 * @constant
 * @type {String}
 * @default
 */
export const FALLBACKLANG = REACT_APP_FALLBACKLANG

/**
 * Returns the language supported by the browser and the site or the default language
 * @return  {String}  language string (BCP47)
 */
export const detectLang = () => {
  const browserLang = navigator.languages ?? []
  return browserLang.find((lang) => LANGUAGES?.includes(lang)) ?? DEFAULTLANG
}

/**
 * Set the HTML lang
 * @param   {String}  language string (BCP47)
 * @return {undefined}
 */
export const setHtmlLang = (lang) => {
  document.documentElement.lang = lang
}

/**
 * Current language (BCP47), defined by detectLang()
 * @constant
 * @type {String}
 * @default
 */
export const LANG = detectLang()
