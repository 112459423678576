import Header from 'presentation/Header'
import Sections from 'presentation/Sections'
import Footer from 'presentation/Footer'

import styles from './home.module.scss'

function Home() {
  return (
    <div className={styles.home__container}>
      <Header />
      <Sections />
      <Footer />
    </div>
  )
}

export default Home
