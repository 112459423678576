import { useEffect } from 'react'

function useOnRendered(onRendered) {
  useEffect(() => {
    window.addEventListener('load', onRendered)

    return () => window.removeEventListener('load', onRendered)
  }, [])
}

export default useOnRendered
