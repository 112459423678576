import PropTypes from 'prop-types'
import c from 'classnames'
import styles from './sections.module.scss'

function Section({ children, className, id, ...props }) {
  return (
    <div id={id} className={c('ga-section', styles.section__container, className)} {...props}>
      {children}
    </div>
  )
}

Section.propTypes = {
  id: PropTypes.string.isRequired, // Necessary for Analytics
  className: PropTypes.string,
  children: PropTypes.element.isRequired,
}

Section.defaultProps = {
  className: null,
}

export default Section
