import { useTranslation } from 'react-i18next'
import { useParams, Link } from 'react-router-dom'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import c from 'classnames'
import './carousel.hero.scss'
import Button from 'presentation/Button'
import imgHero1 from 'assets/images/img-hero-1.webp'
import imgHero1$1920 from 'assets/images/img-hero-1-1920.webp'
import imgHero1$1440 from 'assets/images/img-hero-1-1440.webp'
import imgHero1$768 from 'assets/images/img-hero-1-768.webp'
import imgHero2 from 'assets/images/img-hero-2.webp'
import imgHero2$1920 from 'assets/images/img-hero-2-1920.webp'
import imgHero2$1440 from 'assets/images/img-hero-2-1440.webp'
import imgHero2$768 from 'assets/images/img-hero-2-768.webp'
import imgHero3 from 'assets/images/img-hero-3.webp'
import imgHero3$1920 from 'assets/images/img-hero-3-1920.webp'
import imgHero3$1440 from 'assets/images/img-hero-3-1440.webp'
import imgHero3$768 from 'assets/images/img-hero-3-768.webp'
import imgHero4 from 'assets/images/img-hero-4.webp'
import imgHero4$1920 from 'assets/images/img-hero-4-1920.webp'
import imgHero4$1440 from 'assets/images/img-hero-4-1440.webp'
import imgHero4$768 from 'assets/images/img-hero-4-768.webp'
import imageScaleUp from 'assets/icons/scaleup_logo.svg'
import imageSantander from 'assets/icons/companies/single_color/logo-santander.svg'
import imageSuperdigital from 'assets/icons/companies/single_color/logo-superdigital.svg'
import imageN5 from 'assets/icons/companies/single_color/logo-N5.svg'
import imageC6Bank from 'assets/icons/companies/single_color/logo-c6bank.svg'
import imageOLXAutos from 'assets/icons/companies/single_color/logo-olx-autos.svg'
import imageSaintGobain from 'assets/icons/companies/single_color/logo-saint-gobain.svg'
import imageTailorFrancis from 'assets/icons/companies/single_color/logo-taylor.svg'
import styles from './hero.module.scss'

export default function Hero({ ...props }) {
  const { t } = useTranslation('Hero')
  const { lang } = useParams()

  const renderList = (dataString) => {
    const items = dataString.split(', ')

    return (
      <ul className={styles.list_container}>
        {items.map((item, index) => (
          <li key={`item-${index.toString()}-li`}>{item}</li>
        ))}
      </ul>
    )
  }

  return (
    <div className={styles.hero} {...props}>
      <Carousel
        showStatus={false}
        showThumbs={false}
        stopOnHover={false}
        showArrows={false}
        preventMovementUntilSwipeScrollTolerance
        swipeScrollTolerance={50}
        axis="horizontal"
        autoPlay
        infiniteLoop
        interval={7000}
        transitionTime={1500}
        className={styles.carousel}
      >
        <div id="hero-slide_1" className={c('ga-carrousel_element', styles.slide)}>
          <div className={styles.offer}>
            <p>
              {t('Value_proposition_1.Line_1.Text')}
              <span className={styles.text__accent}>{t('Value_proposition_1.Line_1.Accent')}</span>
              {t('Value_proposition_1.Line_1.Text_2')}
            </p>
            {renderList(t('Value_proposition_1.Line_2.Text'))}
            <span className={styles.text__accent}>{t('Value_proposition_1.Line_2.Accent')}</span>
            {t('Value_proposition_1.Line_2.Text_2')}
            <Link to={`/contact/${lang}/`}>
              <Button className={styles.button__cta}>{t('CTA_button')}</Button>
            </Link>
          </div>
          <picture>
            <source srcSet={imgHero1} media="(min-width: 2240px)" width="2240px" height="100vh" />
            <source
              srcSet={imgHero1$1920}
              media="(min-width: 1680px)"
              width="1680px"
              height="100vh"
            />
            <source
              srcSet={imgHero1$1440}
              media="(min-width: 1104px)"
              width="1104px"
              height="100vh"
            />
            <img
              className={styles.background}
              src={imgHero1$768}
              alt=""
              width="768px"
              height="100vh"
            />
          </picture>
        </div>
        <div id="hero-slide_4" className={c('ga-carrousel_element', styles.slide)}>
          <div className={styles.offer}>
            <p>
              {t('Value_proposition_4.Line_1.Text')}
              <span className={styles.text__accent}>{t('Value_proposition_4.Line_1.Accent')}</span>
              {t('Value_proposition_4.Line_1.Text_2')}
            </p>
            <p className={styles.logo_customers}>
              <img
                height="20px"
                className={styles.logo__inline}
                src={imageSantander}
                alt="Santander"
                style={{ marginBottom: '4px' }}
              />
              <img
                height="20px"
                className={styles.logo__inline}
                src={imageSuperdigital}
                alt="Superdigital"
              />
              <img height="20px" className={styles.logo__inline} src={imageN5} alt="N5" />
              <img height="20px" className={styles.logo__inline} src={imageC6Bank} alt="C6 Bank" />
            </p>
            <p className={styles.logo_customers}>
              <img
                height="20px"
                className={styles.logo__inline}
                src={imageOLXAutos}
                alt="OLX Autos"
              />
              <img
                height="20px"
                className={styles.logo__inline}
                src={imageSaintGobain}
                alt="Saint Gobain"
              />
              <img
                height="20px"
                className={styles.logo__inline}
                src={imageTailorFrancis}
                alt="Tailor & Francis Online"
              />
            </p>
          </div>
          <picture>
            <source srcSet={imgHero4} media="(min-width: 2240px)" width="2240px" height="100vh" />
            <source
              srcSet={imgHero4$1920}
              media="(min-width: 1680px)"
              width="1680px"
              height="100vh"
            />
            <source
              srcSet={imgHero4$1440}
              media="(min-width: 1104px)"
              width="1104px"
              height="100vh"
            />
            <img
              className={c(styles.background, 'align-left')}
              src={imgHero4$768}
              alt=""
              width="768px"
              height="100vh"
            />
          </picture>
        </div>
        <div id="hero-slide_2" className={c('ga-carrousel_element', styles.slide)}>
          <div className={styles.offer}>
            <p>
              {t('Value_proposition_2.Line_1.Text')}
              <span className={styles.text__accent}>{t('Value_proposition_2.Line_1.Accent')}</span>
              {t('Value_proposition_2.Line_1.Text_2')}
            </p>
            <p>
              {t('Value_proposition_2.Line_2.Text')}
              <span className={styles.text__accent}>{t('Value_proposition_2.Line_2.Accent')}</span>
              {t('Value_proposition_2.Line_2.Text_2')}
            </p>
            <Link to={`/contact/${lang}/`}>
              <Button className={styles.button__cta}>{t('CTA_button')}</Button>
            </Link>
          </div>
          <picture>
            <source srcSet={imgHero2} media="(min-width: 2240px)" width="2240px" height="100vh" />
            <source
              srcSet={imgHero2$1920}
              media="(min-width: 1680px)"
              width="1680px"
              height="100vh"
            />
            <source
              srcSet={imgHero2$1440}
              media="(min-width: 1104px)"
              width="1104px"
              height="100vh"
            />
            <img
              className={styles.background}
              src={imgHero2$768}
              alt=""
              width="768px"
              height="100vh"
            />
          </picture>
        </div>
        <div id="hero-slide_3" className={c('ga-carrousel_element', styles.slide)}>
          <div className={styles.offer}>
            <p>
              {t('Value_proposition_3.Line_1.Text')}
              <span className={styles.text__accent}>{t('Value_proposition_3.Line_1.Accent')}</span>
              {t('Value_proposition_3.Line_1.Text_2')}
            </p>
            <p>
              {t('Value_proposition_3.Line_2.Text')}
              <img
                className={c(styles.logo__inline, styles.logo_scale_up)}
                src={imageScaleUp}
                alt="ScaleUp"
              />
              {t('Value_proposition_3.Line_2.Text_2')}
            </p>
            <a href="https://www.scaleup-global.com/" target="_blank" rel="noreferrer">
              <Button className={styles.button__cta}>{t('CTA_button')}</Button>
            </a>
          </div>
          <picture>
            <source srcSet={imgHero3} media="(min-width: 2240px)" width="2240px" height="100vh" />
            <source
              srcSet={imgHero3$1920}
              media="(min-width: 1680px)"
              width="1680px"
              height="100vh"
            />
            <source
              srcSet={imgHero3$1440}
              media="(min-width: 1104px)"
              width="1104px"
              height="100vh"
            />
            <img
              className={styles.background}
              src={imgHero3$768}
              alt=""
              width="768px"
              height="100vh"
            />
          </picture>
        </div>
      </Carousel>
    </div>
  )
}
