import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { FALLBACKLANG, LANG } from 'util/language'
import * as es from 'lang/es'
import * as en from 'lang/en'

// TODO: implement https://www.npmjs.com/package/i18next-browser-languagedetector

i18n.use(initReactI18next).init({
  fallbackLng: FALLBACKLANG,
  lng: LANG,

  // have a common namespace used around the full app
  defaultNS: 'Common',
  fallbackNS: 'Common',

  debug: false,

  interpolation: {
    escapeValue: false, // not needed for react!!
  },

  // react i18next special options (optional)
  // override if needed - omit if ok with defaults

  react: {
    // bindI18n: 'languageChanged',
    // bindI18nStore: '',
    // transEmptyNodeValue: '',
    // transSupportBasicHtmlNodes: true,
    // transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
    useSuspense: true,
  },

  resources: { en, es },
})

export default i18n
