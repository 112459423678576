import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import c from 'classnames'

import santanderLogo from 'assets/icons/companies/color/santander_logo.svg'
import olxLogo from 'assets/icons/companies/color/olx_logo.svg'
import n5Logo from 'assets/icons/companies/color/n5_logo.svg'
import ableToLogo from 'assets/icons/companies/color/ableto_logo.svg'
import paretoLogo from 'assets/icons/companies/color/pareto_logo.svg'
import pasapLogo from 'assets/icons/companies/color/pasap_logo.svg'
import c6bankLogo from 'assets/icons/companies/color/c6bank_logo.svg'
import andreaniLogo from 'assets/icons/companies/color/andreani_logo.svg'
import smartStructuringLogo from 'assets/icons/companies/color/smart_structuring_logo.svg'
import atomLogo from 'assets/icons/companies/color/atom_logo.svg'
import emergenciasLogo from 'assets/icons/companies/color/emergencias_logo.svg'
import megalabsLogo from 'assets/icons/companies/color/megalabs_logo.svg'
import agrotokenLogo from 'assets/icons/companies/color/agrotoken_logo.svg'
import lacajaLogo from 'assets/icons/companies/color/la_caja_logo.svg'

import successCaseSantanderImg from 'assets/images/success-case-santander.webp'
import successCaseOlxImg from 'assets/images/success-case-olx.webp'
import successCaseN5Img from 'assets/images/success-case-n5.webp'
import successCaseAbleToImg from 'assets/images/success-case-ableto.webp'
import successCaseLaCajaToImg from 'assets/images/success-case-lacaja.webp'
import successCaseAgrotokenToImg from 'assets/images/success-case-agrotoken.webp'
import successCasePasapToImg from 'assets/images/success-case-pasap.webp'
import successCaseSmartStructuringToImg from 'assets/images/success-case-smartStructuring.webp'
import successCaseAndreaniToImg from 'assets/images/success-case-andreani.webp'
import successCaseAtomToImg from 'assets/images/success-case-atom.webp'
import successCaseAtomEmergenciasToImg from 'assets/images/success-case-emergencias.webp'
import successCaseAtomMegalabsToImg from 'assets/images/success-case-megalabs.webp'

import styles from './company_card.module.scss'

const CARDS_ASSETS = {
  santander: {
    logo: { src: santanderLogo, width: '159px', height: '28px' },
    image: { src: successCaseSantanderImg, width: '247px', height: '149px' },
  },
  olx: {
    logo: { src: olxLogo, width: '135px', height: '24px' },
    image: { src: successCaseOlxImg, width: '247px', height: '149px' },
  },
  n5: {
    logo: { src: n5Logo, width: '45px', height: '32px' },
    image: { src: successCaseN5Img, width: '247px', height: '149px' },
  },
  ableTo: {
    logo: { src: ableToLogo, width: '109px', height: '22px' },
    image: { src: successCaseAbleToImg, width: '247px', height: '149px' },
  },
  pareto: {
    logo: { src: paretoLogo, width: '110px', height: '28px' },
    image: { src: null, width: '247px', height: '149px' },
  },
  pasap: {
    logo: { src: pasapLogo, width: '139px', height: '30px' },
    image: { src: successCasePasapToImg, width: '247px', height: '149px' },
  },
  c6bank: {
    logo: { src: c6bankLogo, width: '139px', height: '28px' },
    image: { src: null, width: '247px', height: '149px' },
  },
  andreani: {
    logo: { src: andreaniLogo, width: '164px', height: '28px' },
    image: { src: successCaseAndreaniToImg, width: '247px', height: '149px' },
  },
  smartStructuring: {
    logo: { src: smartStructuringLogo, width: '105px', height: '30px' },
    image: { src: successCaseSmartStructuringToImg, width: '247px', height: '149px' },
  },
  atom: {
    logo: { src: atomLogo, width: '110px', height: '30px' },
    image: { src: successCaseAtomToImg, width: '247px', height: '149px' },
  },
  emergencias: {
    logo: { src: emergenciasLogo, width: '80px', height: '30px' },
    image: { src: successCaseAtomEmergenciasToImg, width: '247px', height: '149px' },
  },
  megalabs: {
    logo: { src: megalabsLogo, width: '150px', height: '30px' },
    image: { src: successCaseAtomMegalabsToImg, width: '247px', height: '149px' },
  },
  agrotoken: {
    logo: { src: agrotokenLogo, width: '175px', height: '30px' },
    image: { src: successCaseAgrotokenToImg, width: '247px', height: '149px' },
  },
  lacaja: {
    logo: { src: lacajaLogo, width: '32px', height: '32px' },
    image: { src: successCaseLaCajaToImg, width: '247px', height: '149px' },
  },
}

function CompanyCard({ companyName }) {
  const { t } = useTranslation('Companies')
  const { image, logo } = CARDS_ASSETS[companyName]

  return (
    <div
      id={`success_cases-card_${companyName}`}
      className={c('ga-carrousel_element', styles.card)}
    >
      <div className={styles.card__header}>
        <img
          className={styles.card__image}
          alt={`${companyName}`}
          src={image?.src}
          width={image?.width}
          height={image?.height}
        />
      </div>
      <div className={styles.card__body}>
        <div className={styles.card__logo_container}>
          <img
            className={c(styles.card__logo, styles[companyName])}
            alt={`${companyName} logo`}
            src={logo?.src}
            width={logo?.width}
            height={logo?.height}
          />
        </div>
        <div className={styles.card__description}>{t(`Cards.${companyName}.description`)}</div>
      </div>
    </div>
  )
}

CompanyCard.propTypes = {
  companyName: PropTypes.string.isRequired,
}

export default CompanyCard
