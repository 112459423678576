import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import c from 'classnames'
import styles from './menu.module.scss'

function MenuItem({ children, href, selected, className, external, ...props }) {
  return external ? (
    <a href={href} className={c('ga-menu_item', styles.item, className)} {...props}>
      {children}
    </a>
  ) : (
    <NavLink
      to={href}
      className={c('ga-menu_item', styles.item, className)}
      activeClassName={styles.selected}
      {...props}
    >
      {children}
    </NavLink>
  )
}

MenuItem.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  href: PropTypes.string.isRequired,
  className: PropTypes.string,
  selected: PropTypes.string,
  external: PropTypes.bool,
}

MenuItem.defaultProps = {
  className: '',
  selected: null,
  external: false,
}

export default MenuItem
