import React from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import styles from './message.module.scss'

function Message({ type, children }) {
  const classNames = c(styles.wrapper, {
    [styles.success]: type === 'success',
    [styles.error]: type === 'error',
  })

  return <div className={classNames}>{children}</div>
}

Message.propTypes = {
  type: PropTypes.oneOf(['info', 'success', 'error']),
  children: PropTypes.node.isRequired,
}

Message.defaultProps = {
  type: 'info',
}

export default Message
