import React from 'react'
import { useTranslation } from 'react-i18next'
import Card from 'presentation/Card'
import Title from 'presentation/Title'

import styles from './what_we_do_subsection.module.scss'

export default function WhatWeDoSubsection() {
  const { t } = useTranslation('Services')

  return (
    <div className={styles.subsection}>
      <div className={styles.header}>
        <Title className={styles} pretitle={t('WhatWeDo')} title={t('OurServices')} secondary />
      </div>
      <div className={styles.cards_container}>
        {['design', 'develop', 'dedicated', 'fractional', 'product', 'ai'].map((cardName) => (
          <Card
            key={cardName}
            title={t(`Cards.${cardName}.Title`)}
            description={t(`Cards.${cardName}.Description`)}
          />
        ))}
      </div>
    </div>
  )
}
