import PropTypes from 'prop-types'
import c from 'classnames'
import styles from './button.module.scss'

const Button = ({ children, ...props }) => (
  <button type="button" className={c(styles.button)} {...props}>
    {children}
  </button>
)

Button.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
}

Button.defaultProps = {}

export default Button
