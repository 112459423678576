import React, { useEffect, useRef } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import c from 'classnames'

import background1 from 'assets/images/yellow-dotted 2.png'

import styles from './technologies.module.scss'
import TECHNOLOGIES from './TechnologiesList'

export default function Technologies() {
  const { t } = useTranslation('Technologies')
  const containerRef = useRef(null)

  useEffect(() => {
    const container = containerRef.current
    const centerScroll = (container.scrollWidth - container.clientWidth) / 2

    container.scrollLeft = centerScroll
  }, [])

  return (
    <div className={styles.container}>
      <div className={styles.yellow_dotted} style={{ backgroundImage: `url(${background1})` }} />
      <div className={styles.phrase__container}>
        <div className={styles.phrase__pretitle}>{t('Pretitle')}</div>
        <p className={styles.phrase__text}>
          <Trans
            i18nKey="Technologies:Paragraph"
            components={{
              b: <b />,
            }}
          />
        </p>
      </div>
      <div ref={containerRef} className={styles.technologies__container}>
        <div className={styles.technologies__list}>
          {TECHNOLOGIES.map(({ name, logo, width, height }) => (
            <img
              className={c(styles.technologies__item, styles[name])}
              key={name}
              alt={name}
              src={logo}
              width={width}
              height={height}
            />
          ))}
        </div>
      </div>
    </div>
  )
}
