import React from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import FormControl from 'presentation/FormControl'
import styles from './formInput.module.scss'

function FormInput({ name, label, type, multiline, required, value, onChange, ...restOfProps }) {
  const classNames = c(styles.input)
  return (
    <FormControl label={label} name={name} required={required}>
      {multiline ? (
        <textarea
          className={classNames}
          id={name}
          name={name}
          required={required}
          {...restOfProps}
          onChange={(e) => onChange(e.target.value)}
          value={value}
        />
      ) : (
        <input
          className={classNames}
          type={type}
          id={name}
          name={name}
          required={required}
          {...restOfProps}
          onChange={(e) => onChange(e.target.value)}
          value={value}
        />
      )}
    </FormControl>
  )
}

FormInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['text', 'email', 'password']),
  multiline: PropTypes.bool,
  required: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
}

FormInput.defaultProps = {
  multiline: false,
  required: false,
  type: 'text',
  value: '',
  onChange: () => {},
}

export default FormInput
