import React from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import styles from './formControl.module.scss'

function FormControl({ name, label, required, children }) {
  return (
    <div className={styles.wrapper}>
      <label className={c(styles.label, { [styles.required]: required })} htmlFor={name}>
        {label}
      </label>
      {children}
    </div>
  )
}

FormControl.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  required: PropTypes.bool,
}

FormControl.defaultProps = {
  required: false,
}

export default FormControl
