import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import c from 'classnames'
import LanguageSelector from 'presentation/LanguageSelector'
import { useParams } from 'react-router-dom'
import useOnRendered from 'util/onRendered'
import MenuItem from './MenuItem'
import styles from './menu.module.scss'

function Menu({ className }) {
  const { t } = useTranslation('Menu')
  const { section, lang } = useParams()

  const [showHambuergerMenu, setShowHambuergerMenu] = useState(false)

  const hamburgerClickHadler = () => {
    setShowHambuergerMenu(!showHambuergerMenu)
  }

  const scrollIntoView = () => {
    const target = document.querySelector(`#${section}`)
    if (target != null) {
      target.scrollIntoView()
    }
  }

  useOnRendered(scrollIntoView)

  useEffect(scrollIntoView, [window.location.pathname])

  return (
    <>
      <div role="menu" className={c(className, styles.menu__container)}>
        <MenuItem href={`/hero/${lang}/`}>{t('Home')}</MenuItem>
        <MenuItem href={`/ourservices/${lang}/`}>{t('Services')}</MenuItem>
        <MenuItem href={`/customers/${lang}/`}>{t('Customers')}</MenuItem>
        <MenuItem href={`/technologies/${lang}/`}>{t('Technologies')}</MenuItem>
        <MenuItem href={`/contact/${lang}/`}>{t('Contact')}</MenuItem>
        <MenuItem href={`/contents/${lang}/`} external>
          {t('Contents')}
        </MenuItem>
      </div>
      <div className={styles.hamburger_menu__container}>
        <img
          src="/assets/icons/hamburger-menu.svg"
          className={styles.hamburger_menu__icon}
          alt="menu"
          onClick={hamburgerClickHadler}
          width="35"
          height="35"
        />
        {showHambuergerMenu && (
          <div
            role="menu"
            className={c(className, styles.hamburger_menu)}
            onClick={hamburgerClickHadler}
            tabIndex={0}
          >
            <MenuItem href={`/hero/${lang}/`}>{t('Home')}</MenuItem>
            <MenuItem href={`/ourservices/${lang}/`}>{t('Services')}</MenuItem>
            <MenuItem href={`/customers/${lang}/`}>{t('Customers')}</MenuItem>
            <MenuItem href={`/technologies/${lang}/`}>{t('Technologies')}</MenuItem>
            <MenuItem href={`/contact/${lang}/`}>{t('Contact')}</MenuItem>
            <MenuItem href={`/contents/${lang}/`} external>
              {t('Contents')}
            </MenuItem>
            <LanguageSelector className={styles.language__selector} />
          </div>
        )}
      </div>
    </>
  )
}

Menu.propTypes = {
  className: PropTypes.string,
}

Menu.defaultProps = {
  className: '',
}

export default Menu
