import React from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'

import styles from './title.module.scss'

function Title({ title, pretitle, secondary, className }) {
  return (
    <div className={styles.container}>
      {pretitle && (
        <div className={c(className?.pretitle, styles.pretitle, secondary && styles.secondary)}>
          {pretitle}
        </div>
      )}
      <div className={c(className?.title, styles.title, secondary && styles.secondary)}>
        {title}
      </div>
    </div>
  )
}

Title.propTypes = {
  title: PropTypes.string.isRequired,
  pretitle: PropTypes.string,
  secondary: PropTypes.bool,
  className: PropTypes.objectOf(PropTypes.string),
}

Title.defaultProps = {
  pretitle: null,
  secondary: false,
  className: null,
}

export default Title
